import { Menu as BaseMenu, Transition } from '@headlessui/react';
import { DotsVerticalIcon } from '@heroicons/react/solid';
import classNames from 'classnames';
import React from 'react';

type MenuProps = {
  children?: React.ReactNode;
  buttonClassName?: string;
  buttonText?: React.ReactNode;
  topPosition?: string;
};

const DEFAULT_BUTTON_CONTENT = (
  <DotsVerticalIcon className='h-6 w-6 hover:text-brand' />
);

export function Menu({
  children,
  topPosition,
  buttonClassName,
  buttonText = DEFAULT_BUTTON_CONTENT,
}: MenuProps) {
  return (
    <BaseMenu as='div' className='relative flex text-left'>
      <BaseMenu.Button className={buttonClassName ?? 'ml-auto p-4'}>
        {buttonText}
      </BaseMenu.Button>
      <Transition
        as='div'
        className='z-[60]'
        enter='transition ease-out duration-100'
        enterFrom='transform opacity-0 scale-95 -translate-y-5'
        enterTo='transform opacity-100 scale-100 translate-y-0'
        leave='transition ease-in duration-75'
        leaveFrom='transform opacity-100 scale-100'
        leaveTo='transform opacity-0 scale-95'
      >
        <BaseMenu.Items
          className={classNames(
            topPosition ?? 'top-5',
            'absolute right-0 z-50 mt-3 w-44 origin-top-right rounded-md border border-gray55 bg-white shadow-lg focus:outline-none'
          )}
        >
          {children}
        </BaseMenu.Items>
      </Transition>
    </BaseMenu>
  );
}

function Item(props: Parameters<typeof BaseMenu.Items>[0]) {
  return (
    // @ts-expect-error upstream?
    <BaseMenu.Item
      as={props.as ?? 'div'}
      className='block cursor-pointer border-b border-gray88 px-4 py-5 text-left text-sm font-medium text-copy-body last:border-b-0 hover:text-brand'
      {...props}
    />
  );
}

Menu.Item = Item;

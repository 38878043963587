import Compressor, { CompressorOptions } from '@uppy/compressor';
import Uppy from '@uppy/core';
// import GoldenRetriever from '@uppy/golden-retriever';
import ThumbnailGenerator from '@uppy/thumbnail-generator';
import XHR, { XHRUploadOptions } from '@uppy/xhr-upload';

export type Meta = Record<string, unknown>;

export type Body = {
  id: string;
  kind: 'file' | 'image';
  url: string;
};

const MiB = 0x10_00_00;
let NEXT_ID = 0;

// List of CORS AllowedHeaders for S3 compatible put object request
const ALLOWED_HEADERS = [
  'Content-Type',
  'x-amz-meta-environment',
  'x-amz-meta-tenantid',
  'x-amz-meta-originalfilename',
  'x-amz-meta-source',
  'x-amz-meta-tenantid',
];

const compressorOptions: CompressorOptions = {
  quality: 0.6,
  maxWidth: 1366,
  maxHeight: 1366,
};

const xhrOptions: XHRUploadOptions<Meta, Body> = {
  endpoint: '/api/uploadv2',
  allowedMetaFields: [],
};

export function createUppy(imagesOnly = false, max?: number, id?: string) {
  const uppy = new Uppy<Meta, Body>({
    id: id ?? `uppy-${++NEXT_ID}`,
    allowMultipleUploadBatches: true,
    autoProceed: true,
    restrictions: {
      maxFileSize: 10 * MiB,
      allowedFileTypes: imagesOnly ? ['image/*'] : undefined,
      maxNumberOfFiles: max,
    },
    debug: true, //process.env.NODE_ENV === 'development',
    onBeforeFileAdded(file, files) {
      // Allow duplicate files
      return true;
    },
  })
    .use(Compressor, compressorOptions)
    .use(XHR, xhrOptions)
    // .use(GoldenRetriever)
    .use(ThumbnailGenerator);

  const files = uppy.getFiles();
  console.log('uppy ready %i files found', files.length);
  if (files.length) {
    files.forEach(async (restoredFile) => {
      if (!restoredFile.progress?.uploadComplete) {
        console.log('retrying', restoredFile);
        await uppy.retryUpload(restoredFile.id);
      }
      // clean up
      uppy.removeFile(restoredFile.id);
    });
  }

  return uppy;
}

type CloudflareResponse = {
  errors: unknown[];
  messages: unknown[];
  result: {
    filename: string;
    id: string;
    meta: Record<string, unknown>;
    requireSignedURLs: boolean;
    uploaded: string;
    variants: string[];
  };
  success: boolean;
};

function isCloudflareResponse(
  body: Record<string, unknown>
): body is CloudflareResponse {
  return (
    'result' in body &&
    typeof body.result === 'object' &&
    body.result !== null &&
    'id' in body.result &&
    typeof body.result.id === 'string'
  );
}

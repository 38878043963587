import { faCaretRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Combobox, Disclosure } from '@headlessui/react';
import { PlusIcon } from '@heroicons/react/solid';
import classNames from 'classnames';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ConditionalWrapper from '~/helpers/ConditionalWrapper';
import { by, notUndefined } from '~/helpers/filter';
import { styles } from '../FindAddContact/ContactSelect';
import { SearchInput } from '../FindAddContact/SearchInput';

type Identifiable = {
  $group?: string;
  $subgroup?: string;
  id: string | number;
  name: string;
};

type Props<T extends Identifiable> = {
  items: T[];
  searchKeys: (keyof T)[];
  placeholder?: string;
  value: T[];
  onChange: (value: T[]) => void;
  closePopover?: () => void;
  onCreate?: (query: string) => void;
  /**
   * Only used if `onCreate` is set.
   */
  createText?: string;
  multiple: boolean;
  searchable?: boolean;
  selectAll?: boolean;
  renderEmpty?: () => React.ReactNode;
  renderInner: (item: T) => React.ReactNode;
  height?: string;
};

const isString = (value: unknown): value is string => typeof value === 'string';
/**
 * Combobox with static, always visible list of options. Use inside a popover, dialog, command palette etc.
 */
export function Combolist<T extends Identifiable>({
  items,
  searchKeys,
  placeholder = '',
  value,
  onChange,
  closePopover,
  onCreate,
  createText,
  multiple,
  searchable = true,
  selectAll,
  renderEmpty,
  renderInner,
  height,
}: Props<T>) {
  const { t } = useTranslation();
  const [query, setQuery] = useState('');
  const filteredItems =
    query === '' ? items : items.filter(by(query, searchKeys));

  const selected = value
    .map((val) => {
      return isString(val) ? items.find((item) => item.id === val) : val;
    })
    .filter(notUndefined);

  const allFilteredSelected = filteredItems.every((item) =>
    selected.map(({ id }) => id).includes(item.id)
  );

  function handleChange(newValue: T[]) {
    // Multiple mode
    if (Array.isArray(newValue)) {
      if (
        onCreate &&
        newValue.length > 0 &&
        newValue[newValue.length - 1] === null
      ) {
        onCreate(query);
        return;
      }
      if (
        selectAll &&
        newValue.length > 0 &&
        newValue[newValue.length - 1] === null
      ) {
        onChange(value.length >= items.length ? [] : items);
        return;
      }
      onChange(newValue);
      return;
    }

    // Single mode (still passes an array)
    if (onCreate && newValue === null) {
      onCreate(query);
      return;
    }
    onChange(newValue ? [newValue] : []);
  }

  const NOGROUP = 'nogroup';

  const groupedItems = filteredItems.reduce<{
    [group: string]: { [subgroup: string]: T[] };
  }>((acc, curr) => {
    const currGroup = curr.$group || NOGROUP;
    if (!acc[currGroup]) {
      acc[currGroup] = {};
    }

    const currSubGroup = curr.$subgroup || NOGROUP;
    if (!acc[currGroup][currSubGroup]) {
      acc[currGroup][currSubGroup] = [];
    }

    acc[currGroup][currSubGroup].push(curr);

    return acc;
  }, {});

  return (
    <Combobox
      value={multiple ? value : value.length > 0 ? value[0] : undefined}
      onChange={handleChange}
      multiple={multiple}
      // @ts-expect-error upstream type issue
      by={multiple ? (a, b) => a?.id === b?.id : undefined}
    >
      {searchable && (
        <div className='px-2 py-1'>
          <SearchInput
            className='rounded-full border-none bg-grey-3'
            inputProps={{ className: 'bg-grey-3' }}
            placeholder={placeholder}
            setQuery={setQuery}
            query={query}
          />
        </div>
      )}

      <Combobox.Options
        static
        className={classNames(
          'flex scroll-py-3 flex-col overflow-y-auto',
          height ? height : 'max-h-64'
        )}
      >
        {items.length === 0 && renderEmpty && (
          <div className='p-2 text-center text-base text-copy-alt'>
            {renderEmpty()}
          </div>
        )}
        {!onCreate && query && filteredItems.length === 0 && (
          <div className='p-2 text-center text-base text-copy-alt'>
            No matching {placeholder ? placeholder.toLowerCase() : 'items'}
          </div>
        )}
        {onCreate && (
          <Combobox.Option
            value={null}
            className={({ active }) =>
              classNames(
                'flex cursor-default select-none items-center justify-between px-4 py-2',
                active ? 'bg-brand text-white' : 'text-gray-900'
              )
            }
            key={'create'}
            // onClick={() => onCreate(query)}
          >
            {({ active }) => (
              <div className='flex items-center gap-2 truncate whitespace-nowrap'>
                <div className='p-1'>
                  <PlusIcon
                    className={`h-5 w-5 ${
                      active ? 'text-white' : 'text-brand'
                    }`}
                    aria-hidden='true'
                  />
                </div>
                <div>
                  {createText ?? 'Create' /* {query && `"${query}"`} */}
                </div>
              </div>
            )}
          </Combobox.Option>
        )}
        {selectAll && filteredItems.length > 0 && (
          <Combobox.Option
            value={null}
            className={({ active }) =>
              classNames(
                'flex cursor-default select-none items-center justify-between',
                active ? 'bg-brand text-white' : 'text-gray-900'
              )
            }
            key={'all'}
            onClick={(e) => {
              e.preventDefault();
              onChange(allFilteredSelected ? [] : filteredItems);
            }}
          >
            {({ active }) => (
              <div className='flex w-full items-center'>
                <div className='flex items-center justify-center py-2 pl-5'>
                  <input
                    className={classNames(
                      'z-30 h-4 w-4 cursor-pointer accent-brand',
                      {
                        hidden: allFilteredSelected,
                      }
                    )}
                    type='checkbox'
                    checked={false}
                  />
                  {allFilteredSelected && (
                    <label
                      className={classNames(
                        'flex h-4 w-4 cursor-pointer items-center justify-center rounded-[2px] border border-solid border-white bg-brand pb-[3px] text-xl text-white',
                        {
                          'rounded-none': active,
                        }
                      )}
                      htmlFor='box'
                    >
                      -
                    </label>
                  )}
                </div>
                <div
                  className={classNames(
                    'flex flex-1 items-center justify-between truncate py-2 pl-2 pr-4'
                  )}
                >
                  <div className='flex items-center gap-2 truncate whitespace-nowrap pl-[1px]'>
                    <span className='truncate text-base'>{t('selectAll')}</span>
                  </div>
                </div>
              </div>
            )}
          </Combobox.Option>
        )}
        {Object.entries(groupedItems)
          .sort(([groupA]) => (groupA === NOGROUP ? -1 : 1))
          .map(([group, subgroups]) => {
            return (
              <ConditionalWrapper
                condition={group !== NOGROUP}
                wrapper={(children) => (
                  <Disclosure defaultOpen key={group}>
                    {({ open }) => (
                      <>
                        <Disclosure.Button
                          className={classNames(
                            'mx-2 border-t py-2 text-start first:border-none',
                            Object.values(subgroups)
                              .flat()
                              .some(({ id }) =>
                                value.some(({ id: valueId }) => valueId === id)
                              )
                              ? 'font-semibold text-copy-alt'
                              : 'font-medium text-copy-light'
                          )}
                        >
                          <FontAwesomeIcon
                            className='mx-2 text-copy-light'
                            icon={faCaretRight}
                            rotation={open ? 90 : undefined}
                            size='xs'
                          />
                          {group}
                        </Disclosure.Button>
                        <Disclosure.Panel>{children}</Disclosure.Panel>
                      </>
                    )}
                  </Disclosure>
                )}
              >
                <>
                  {Object.entries(subgroups)
                    .sort(([subgroupA]) => (subgroupA === NOGROUP ? -1 : 1))
                    .map(([subgroup, items]) => (
                      <>
                        <ConditionalWrapper
                          condition={subgroup !== NOGROUP}
                          wrapper={(children) => (
                            <Disclosure defaultOpen key={subgroup}>
                              {({ open }) => (
                                <>
                                  <Disclosure.Button
                                    className={classNames(
                                      'w-full py-2 pl-7 text-start first:border-none',
                                      items.some(({ id }) =>
                                        value.some(
                                          ({ id: valueId }) => valueId === id
                                        )
                                      )
                                        ? 'font-semibold text-copy-alt'
                                        : 'font-medium text-copy-light'
                                    )}
                                  >
                                    <FontAwesomeIcon
                                      className='mx-2 text-copy-light'
                                      icon={faCaretRight}
                                      rotation={open ? 90 : undefined}
                                      size='xs'
                                    />
                                    {subgroup}
                                  </Disclosure.Button>
                                  <Disclosure.Panel>
                                    {children}
                                  </Disclosure.Panel>
                                </>
                              )}
                            </Disclosure>
                          )}
                        >
                          <>
                            {items.map((item) => (
                              <Combobox.Option
                                key={item.id}
                                value={item}
                                className={styles.option}
                              >
                                {({ active, selected }) => (
                                  <div className='flex w-full items-center'>
                                    {multiple &&
                                      (group === NOGROUP ||
                                        subgroup === NOGROUP) && (
                                        <div className='flex items-center justify-center py-2 pl-5'>
                                          <input
                                            className={classNames(
                                              'z-30 h-4 w-4 cursor-pointer accent-brand',
                                              {
                                                'outline outline-[1.3px] outline-white':
                                                  active && selected,
                                              }
                                            )}
                                            type='checkbox'
                                            checked={selected}
                                          />
                                        </div>
                                      )}
                                    <div
                                      onClick={() => {
                                        setTimeout(() => closePopover?.(), 100);
                                      }}
                                      className={classNames(
                                        'flex flex-1 items-center justify-between truncate py-2 pr-4',
                                        group === NOGROUP ||
                                          subgroup === NOGROUP
                                          ? multiple
                                            ? 'pl-2'
                                            : 'pl-4'
                                          : 'pl-14'
                                      )}
                                    >
                                      <div
                                        className={classNames(
                                          'flex items-center gap-2 truncate whitespace-nowrap'
                                        )}
                                      >
                                        {renderInner(item)}
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </Combobox.Option>
                            ))}
                          </>
                        </ConditionalWrapper>
                      </>
                    ))}
                </>
              </ConditionalWrapper>
            );
          })}
      </Combobox.Options>
    </Combobox>
  );
}

import { ScrollContainer } from 'react-indiana-drag-scroll';
import { Option } from '~/types';
import {
  FilterToggle,
  FilterViewToggle,
} from '../form/checkradio/FilterToggle';
import {
  FacetedFilterOption,
  FacetedFilterSelect,
} from './FacetedFilterSelect';
import { DateRange, FilterDateRange } from './FilterDateRange';
import { FilterSelect } from './FilterSelect';

import 'react-indiana-drag-scroll/dist/style.css';

export type Select = {
  name: string;
  label: string;
  options: Option[];
  multiple?: boolean;
  searchable?: boolean;
  showLabel?: 'always' | 'auto';
  type: 'select';
};

type FacetedSelect = {
  name: string;
  label: string;
  options: FacetedFilterOption[];
  type: 'facetedSelect';
};

type Toggle = {
  name: string;
  label: string;
  toggle: string;
  type: 'toggle';
};

type ViewToggle = {
  name: string;
  label: string;
  toggle: string;
  type: 'viewToggle';
};

type Date = {
  name: string;
  label?: string;
  type: 'date';
};

/**
 * For convenience allow boolean here to allow shorter configuration syntax
 * i.e. Can turn on/off a filter by passing (options.length > 0 && [the options])
 */
export type Filter =
  | boolean
  | Select
  | FacetedSelect
  | Toggle
  | ViewToggle
  | Date
  | 'divider';

type FF = Exclude<Filter, boolean>;

// Type guards for each filter type

function isDivider(filter: Filter): filter is 'divider' {
  return filter === 'divider';
}

function isSelect(filter: FF): filter is Select {
  return typeof filter !== 'string' && filter.type === 'select';
}

function isFacetedSelect(filter: FF): filter is FacetedSelect {
  return typeof filter !== 'string' && filter.type === 'facetedSelect';
}

function isToggle(filter: FF): filter is Toggle {
  return typeof filter !== 'string' && filter.type === 'toggle';
}

function isViewToggle(filter: FF): filter is ViewToggle {
  return typeof filter !== 'string' && filter.type === 'viewToggle';
}

function isDate(filter: FF): filter is Date {
  return typeof filter !== 'string' && filter.type === 'date';
}

// Used to seperate key and value in the value of a faceted filter
// in the URLSearchParams
export const filterKeyValueSeperator = '--';

type Props = {
  filters: Filter[];
  value: URLSearchParams;
  onChange: (value: URLSearchParams) => void;
  placement?: 'portal';
};

export function FilterGroup({ filters, value, onChange, placement }: Props) {
  // loop through filters and create a FilterSelect for each
  // pass in the filter label, options, and value
  // value can be retrieved from URLSearchParams using getAll(name)
  // onChange should return a new instance of URLSearchParams

  const filterComponents = filters
    .filter((f): f is FF => typeof f !== 'boolean')
    .map((filter, index) => {
      if (isDivider(filter)) {
        return (
          <div key={`divider-index-${index}`} className='m-1.5 h-8 border-l' />
        );
      } else if (isSelect(filter)) {
        return (
          <FilterSelect
            key={filter.name}
            label={filter.label}
            options={filter.options}
            value={value.getAll(filter.name)}
            onChange={(filterValue) => {
              // Create new params from existing value
              const params = new URLSearchParams(value);
              // Delete params related to this filter
              params.delete(filter.name);
              // Add new params for this filter
              filterValue.forEach((v) => params.append(filter.name, v));
              onChange(params);
            }}
            multiple={filter.multiple}
            searchable={filter.searchable}
            showLabel={filter.showLabel}
            placement={placement}
          />
        );
      } else if (isFacetedSelect(filter)) {
        return (
          <FacetedFilterSelect
            key={filter.name}
            label={filter.label}
            options={filter.options}
            value={value.getAll(filter.name).reduce((acc, v) => {
              const [key, ...value] = v.split(filterKeyValueSeperator);
              return [...acc, { key, value }];
            }, [] as { key: string; value: string[] }[])}
            onChange={(filterValue) => {
              // Create new params from existing value
              const params = new URLSearchParams(value);
              // Delete params related to this filter
              params.delete(filter.name);
              // Add new params for this filter
              filterValue.forEach(({ key, value }) =>
                params.append(
                  filter.name,
                  [key, ...value].join(filterKeyValueSeperator)
                )
              );
              onChange(params);
            }}
          />
        );
      } else if (isToggle(filter)) {
        return (
          <FilterToggle
            key={filter.name}
            label={filter.label}
            toggle={filter.toggle}
            value={value.get(filter.name) ?? ''}
            onChange={(filterValue) => {
              // Create new params from existing value
              const params = new URLSearchParams(value);
              // Delete params related to this filter
              params.delete(filter.name);
              // Add new params for this filter
              params.append(filter.name, filterValue);
              onChange(params);
            }}
          />
        );
      } else if (isViewToggle(filter)) {
        return (
          <FilterViewToggle
            key={filter.name}
            label={filter.label}
            toggle={filter.toggle}
            value={value.get(filter.name) ?? ''}
            onChange={(filterValue) => {
              // Create new params from existing value
              const params = new URLSearchParams(value);
              // Delete params related to this filter
              params.delete(filter.name);
              // Add new params for this filter
              params.append(filter.name, filterValue);
              onChange(params);
            }}
          />
        );
      } else if (isDate(filter)) {
        // Else this is a date range filter
        const afterString = value.get(filter.name + 'After');
        const beforeString = value.get(filter.name + 'Before');
        const dateRangeValue: DateRange = {
          afterDate: afterString ? new Date(afterString) : undefined,
          beforeDate: beforeString ? new Date(beforeString) : undefined,
        };
        return (
          <FilterDateRange
            key={filter.name}
            label={filter.label}
            value={dateRangeValue}
            onChange={(newDateRange) => {
              // Create new params from existing value
              const params = new URLSearchParams(value);
              // Delete params related to this filter
              params.delete(filter.name + 'After');
              params.delete(filter.name + 'Before');
              // Add new params for this filter
              if (newDateRange?.afterDate) {
                params.append(
                  filter.name + 'After',
                  newDateRange.afterDate.toISOString()
                );
              }
              if (newDateRange?.beforeDate) {
                params.append(
                  filter.name + 'Before',
                  newDateRange.beforeDate.toISOString()
                );
              }
              onChange(params);
            }}
          />
        );
      } else {
        return null;
      }
    });

  return (
    <ScrollContainer
      mouseScroll={{
        ignoreElements: '.prevent-drag-scroll',
      }}
    >
      <div className='space flex w-full items-center px-2 lg:p-0'>
        {filterComponents}
      </div>
    </ScrollContainer>
  );
}

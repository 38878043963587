import { Transition } from '@headlessui/react';
import { ArrowLeftIcon } from '@heroicons/react/outline';
import { createContext, useContext } from 'react';

type SubpageProps = {
  title: string;
  isOpen: boolean;
  backTo: () => void;
  children?: React.ReactNode;
  form?: React.ReactNode;
};

type Context = {
  backTo: () => void;
};

const SettingsSubpageContext = createContext({} as Context);

export const useSettingsContext = () => useContext(SettingsSubpageContext);

export const SettingsSubpage = ({
  title,
  isOpen,
  backTo,
  children,
  form,
}: SubpageProps) => {
  return (
    <SettingsSubpageContext.Provider value={{ backTo }}>
      <Transition
        as='div'
        show={isOpen}
        enter='ease-out duration-300 origin-right'
        enterFrom='opacity-0 scale-x-0'
        enterTo='opacity-100 scale-x-100'
        leave='duration-300 origin-left'
        leaveFrom='opacity-100 scale-x-100'
        leaveTo='opacity-0 scale-x-0'
        className='fixed inset-0 z-50 overflow-y-auto rounded-2xl bg-white p-8 lg:absolute lg:p-12'
      >
        <div className='flex h-full flex-col'>
          <div className='flex items-center gap-4 pb-10'>
            <ArrowLeftIcon
              onClick={backTo}
              className='h-5 cursor-pointer transition-all duration-300 hover:text-brand'
            />
            <h1 className='text-lg font-medium'>{title}</h1>
          </div>
          {children ?? form}
        </div>
      </Transition>
    </SettingsSubpageContext.Provider>
  );
};

import { useField } from 'formik';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RequestPolicy, useQuery } from 'urql';
import { PopupDialogNew } from '~/components/ui/PopupDialogNew';
import { useCreateSiteMutation } from '~/generated/graphql';
import { graphql } from '~/gql';
import { ListSitesQuery, SiteInput } from '~/gql/graphql';
import { ShortSiteForm } from '../sites/forms/ShortSiteForm';
import { SiteSelect, SiteSelectProps } from './SiteSelect';

export type Site = ListSitesQuery['sites'][number];

type Props = Omit<SiteSelectProps, 'sites' | 'onCreate'>;

type FieldProps = Omit<Props, 'value' | 'onChange'> & {
  name: string;
};

const ListSitesDocument = graphql(`
  query ListSites {
    sites(status: Active) {
      id
      status
      type
      name
      address
      state
      image
      activeLocations
      attributes(filter: true) {
        id
        type
        category
        name
        value
      }
    }
  }
`);

// TODO global state?
export function useSites({
  requestPolicy,
}: {
  requestPolicy?: RequestPolicy;
} = {}): [Site[], () => void] {
  const [result, reexecuteQuery] = useQuery({
    query: ListSitesDocument,
    requestPolicy,
  });
  return [
    result.data?.sites ?? [],
    () => reexecuteQuery({ requestPolicy: 'network-only' }),
  ];
}

export const FindAddSite = (props: Props) => {
  const [sites, refresh] = useSites();
  const [, create] = useCreateSiteMutation();
  const [showCreateSitePopup, setShowCreateSitePopup] = useState<
    string | false
  >(false);
  async function handleSubmit(data: SiteInput) {
    const result = await create({ input: data });

    if (result.error) {
      // TODO: Handle error
      return;
    }

    if (result.data?.createSite) {
      refresh();
      props.onChange([result.data.createSite]);
      setShowCreateSitePopup(false);
    }
  }

  return (
    <div>
      <CreateSitePopup
        show={showCreateSitePopup}
        onClose={() => setShowCreateSitePopup(false)}
        onSubmit={handleSubmit}
      />
      <SiteSelect
        {...props}
        sites={sites}
        // TODO: Temporary disabled feature. Fix and reimplement. (See #186)
        // onCreate={setShowCreateSitePopup}
      />
    </div>
  );
};

type CreateSitePopupProps = {
  show: string | boolean;
  onClose: () => void;
  onSubmit: (values: SiteInput) => void;
};

function CreateSitePopup({ show, onClose, onSubmit }: CreateSitePopupProps) {
  const { t } = useTranslation();
  const defaultName = typeof show === 'string' ? show : undefined;

  return (
    <PopupDialogNew isOpen={show !== false} onClose={onClose}>
      <PopupDialogNew.Title>{t('createSite')}</PopupDialogNew.Title>
      <ShortSiteForm
        defaultName={defaultName}
        onSubmit={(input) => onSubmit(input)}
      />
    </PopupDialogNew>
  );
}

export function FindAddSiteField({ name, ...props }: FieldProps) {
  const [contacts] = useSites();
  const [field, meta, helpers] = useField(name);

  return (
    <>
      <FindAddSite
        {...props}
        value={
          field.value ? contacts.filter(({ id }) => id === field.value) : []
        }
        onChange={(val) => helpers.setValue(val.length ? val[0].id : null)}
      />
      {meta.error && <p className='ErrorMessage'>{meta.error}</p>}
    </>
  );
}

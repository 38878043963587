import {
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import {
  addDays,
  areIntervalsOverlapping,
  endOfWeek,
  isWithinInterval,
  startOfDay,
} from 'date-fns';
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { MdAdd, MdRemove } from 'react-icons/md';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery } from 'urql';
import { Can } from '~/components/Can';
import { Forbidden } from '~/components/Forbidden';
import { Layout } from '~/components/Layout';
import { ScheduleView } from '~/components/ScheduleView';
import { filterKeyValueSeperator } from '~/components/ui/FilterGroup';
import { FilterGroupSearch } from '~/components/ui/FilterGroupSearch';
import { Loading } from '~/components/ui/Loading';
import { graphql } from '~/gql';
import { JobStatus, ScheduleQuery } from '~/gql/graphql';
import { unique } from '~/helpers/array';
import parseFilters from '~/helpers/parseFilters';
import { useSearch } from '~/hooks/useSearch';
import { JobsNav } from '~/layouts/nav/JobsNav';
import { extractAttributes, useSites } from './resources/sites';

export type ScheduleViewData = ScheduleQuery['sites'];

const ScheduleDataContext = createContext<{} | null>(null);

export function useScheduleDataContext() {
  return useContext(ScheduleDataContext);
}

const ScheduleDocument = graphql(/* GraphQL */ `
  query Schedule($names: [String!], $filters: ScheduleFilters) {
    tags(entityTypes: "Job") {
      nodes {
        id
        entityType
        category
        name
        description
        colour
      }
    }
    assignees: contacts(status: Active) {
      id
      name
    }
    sites(names: $names, status: Active) {
      id
      name
      address
      image
      calendarBounds
      attributes {
        id
        type
        category
        name
        value
        __typename
      }
      calendar(filters: $filters) {
        id
        status
        assignee {
          id
          name
          image
        }
        included {
          id
          name
          image
        }
        attributes {
          id
          type
          category
          name
          value
          __typename
        }
        tags
        summary
        start
        end
        scheduledStartTime
        scheduledEndTime
        days
      }
    }
  }
`);

const SEARCH_OPTIONS = {
  keys: ['name', 'address', 'calendar.summary'],
};

const ZOOM_LEVELS = ['month', 'default', 'week'] as const;
export type ZoomLevel = (typeof ZOOM_LEVELS)[number];

export function parseAttributeFilters(values: string[] | undefined) {
  return values
    ? values.reduce<{ id: string; value: string[] }[]>((acc, filter) => {
        const [key, ...value] = filter.split(filterKeyValueSeperator);
        const index = acc.findIndex((f) => f.id === key);
        if (index === -1) {
          acc.push({ id: key, value: value });
        } else {
          acc[index].value.push(...value);
        }
        return acc;
      }, [])
    : [];
}

export default function CalendarRoute() {
  const { jobId } = useParams();

  const { t } = useTranslation(['translation', 'job']);
  const sites = useSites();

  const [searchValue, setSearchValue] = useState('');
  const [filtersValue, setFiltersValue] = useState<URLSearchParams>(
    new URLSearchParams(
      window.localStorage.getItem('form.filter.calendar') || []
    )
  );

  useEffect(() => {
    window.localStorage.setItem(
      'form.filter.calendar',
      filtersValue.toString()
    );
  }, [filtersValue]);

  const [navigateInDirection, setNavigateInDirection] = useState<
    'prev' | 'next' | null
  >(null);

  const [{ data }, reexecuteQuery] = useQuery({
    query: ScheduleDocument,
    variables: {},
    requestPolicy: 'cache-first',
  });
  const tags = data?.tags.nodes ?? [];
  const navigate = useNavigate();
  const { results, search } = useSearch(data?.sites, SEARCH_OPTIONS);
  const filtered = applyFilters(results, parseFilters(filtersValue));
  /**
   * @deprecated All mutations on jobs now automatically update the cached ScheduleView entities
   * It is no longer necessary to manually reload the schedule
   * This method is kept for testing and quick implementations of new features, but cache updates
   * should be made for all mutations that affect the ScheduleView in production. @see {@link file://./../cache.ts}
   */
  const reload = useCallback(() => {
    console.log('RELOAD SCHEDULE');

    reexecuteQuery({ requestPolicy: 'network-only' });
  }, [reexecuteQuery]);

  const attributeOptions = extractAttributes(sites);

  const [scrollDate, setScrollDate] = useState<Date | null>(null);

  useEffect(() => {
    const allJobs = data?.sites.flatMap((site) => site.calendar) ?? [];
    const selectedJob = allJobs.find((job) => job.id === jobId);
    const selectedJobDate = selectedJob?.start
      ? new Date(selectedJob?.start)
      : selectedJob?.end
      ? new Date(selectedJob?.end)
      : null;
    setScrollDate(selectedJobDate);
    console.log(selectedJobDate);
  }, [jobId, data]);

  const [zoomIndex, setZoomIndex] = useState(1);
  const zoomLevel = ZOOM_LEVELS[zoomIndex];
  return (
    <ScheduleDataContext.Provider value={{}}>
      <div className='border-b border-grey-20'>
        <JobsNav view='scheduled' />
      </div>
      <Layout rsbSmall>
        <div className='flex items-center gap-4 overflow-hidden border-b pl-8 pr-4 lg:pr-0'>
          <FilterGroupSearch
            filters={[
              {
                name: 'locationName',
                label: t('site'),
                options:
                  unique(
                    sites
                      .filter(({ status }) => status === 'Active')
                      .map(({ name }) => name)
                  ).map((value) => ({
                    value,
                    label: value,
                  })) ?? [],
                type: 'select',
              },
              'divider',
              {
                name: 'checkIn',
                label: t('checkIn'),
                options: [
                  { value: 'endOfWeek', label: 'This week' },
                  { value: '1', label: 'Today' },
                  { value: 'tomorrow', label: 'Tomorrow' },
                  { value: '3', label: 'Next 3 days' },
                  { value: '7', label: 'Next 7 days' },
                  { value: '14', label: 'Next 14 days' },
                  { value: '30', label: 'Next 30 days' },
                  { value: '365', label: 'Next 12 months' },
                ],
                showLabel: 'always',
                searchable: false,
                multiple: false,
                type: 'select',
              },
              {
                name: 'checkOut',
                label: t('checkOut'),
                options: [
                  { value: 'endOfWeek', label: 'This week' },
                  { value: '1', label: 'Today' },
                  { value: 'tomorrow', label: 'Tomorrow' },
                  { value: '3', label: 'Next 3 days' },
                  { value: '7', label: 'Next 7 days' },
                  { value: '14', label: 'Next 14 days' },
                  { value: '30', label: 'Next 30 days' },
                  { value: '365', label: 'Next 12 months' },
                ],
                showLabel: 'always',
                searchable: false,
                multiple: false,
                type: 'select',
              },
              'divider',
              {
                name: 'scheduled',
                label: t('job_plural'),
                options: [
                  { value: 'endOfWeek', label: 'This week' },
                  { value: '1', label: 'Today' },
                  { value: 'tomorrow', label: 'Tomorrow' },
                  { value: '3', label: 'Next 3 days' },
                  { value: '7', label: 'Next 7 days' },
                  { value: '14', label: 'Next 14 days' },
                  { value: '30', label: 'Next 30 days' },
                  { value: '365', label: 'Next 12 months' },
                ],
                showLabel: 'always',
                searchable: false,
                multiple: false,
                type: 'select',
              },
              {
                name: 'assignee',
                label: t('assignee'),
                options:
                  data?.assignees?.map(({ id, name }) => ({
                    value: id,
                    label: name,
                  })) ?? [],
                showLabel: 'always',
                type: 'select',
              },
              {
                name: 'included',
                label: t('included'),
                options:
                  data?.assignees?.map(({ id, name }) => ({
                    value: id,
                    label: name,
                  })) ?? [],
                showLabel: 'always',
                type: 'select',
              },
              {
                name: 'status',
                label: t('status'),
                options: [
                  { value: JobStatus.Created, label: t('job:Created') },
                  {
                    value: JobStatus.InProgress,
                    label: t('job:InProgress'),
                  },
                  { value: JobStatus.Offered, label: t('job:Offered') },
                  { value: JobStatus.Accepted, label: t('job:Accepted') },
                  { value: JobStatus.Declined, label: t('job:Declined') },
                  { value: JobStatus.Cancelled, label: t('job:Cancelled') },
                  { value: JobStatus.Complete, label: t('job:Complete') },
                ],
                searchable: false,
                type: 'select',
              },
              ...(tags.length > 0
                ? [
                    {
                      name: 'tags',
                      label: t('tag_plural'),
                      options: tags.map(({ id, name }) => ({
                        value: id,
                        label: name,
                      })),
                      type: 'select' as const,
                    },
                  ]
                : []),
              {
                name: 'attributes',
                label: t('attribute_plural'),
                options: attributeOptions,
                type: 'facetedSelect',
              },
              {
                name: 'jobDetail',
                label: t('showScheduledTimes'),
                toggle: 'hidden',
                type: 'viewToggle',
              },
              {
                name: 'bookings',
                label: t('showBookings'),
                toggle: 'hidden',
                type: 'viewToggle',
              },
            ]}
            value={{ searchValue, filtersValue }}
            onChange={({ searchValue, filtersValue }) => {
              setSearchValue(searchValue);
              setFiltersValue(filtersValue);

              search(searchValue);
            }}
          />
          <div className='ml-auto mr-8 flex items-center gap-2'>
            <DateRangeNavButton
              direction='prev'
              onClick={() => {
                setNavigateInDirection('prev');
              }}
            />
            <button
              className='rounded-md border px-3 py-2 hover:bg-gray-50'
              onClick={() => {
                setScrollDate(new Date());
              }}
            >
              Today
            </button>
            <DateRangeNavButton
              direction='next'
              onClick={() => {
                setNavigateInDirection('next');
              }}
            />
          </div>
        </div>

        <Can do='read' on='feat.schedule_view'>
          {filtered ? (
            <ScheduleView
              scrollDate={scrollDate}
              selectedJobId={jobId}
              data={filtered ?? []}
              onClick={(to) => navigate(to)}
              jobDetail={filtersValue.get('jobDetail') !== 'hidden'}
              bookingsHidden={filtersValue.get('bookings') === 'hidden'}
              reload={() => {}} // We handle this fully in the cache. This is deprecated
              zoomLevel={zoomLevel}
              navigateInDirection={navigateInDirection}
              afterNavigate={() => setNavigateInDirection(null)}
            >
              <ZoomControls zoomIndex={zoomIndex} setZoomIndex={setZoomIndex} />
            </ScheduleView>
          ) : (
            <div className='flex h-48 w-full items-center justify-center'>
              <Loading spinner />
            </div>
          )}
        </Can>
        <Can not do='read' on='feat.schedule_view'>
          <Forbidden />
        </Can>
      </Layout>
    </ScheduleDataContext.Provider>
  );
}

function ZoomControls({
  zoomIndex,
  setZoomIndex,
}: {
  zoomIndex: number;
  setZoomIndex: (index: number) => void;
}) {
  const [canZoomOut, canZoomIn] = [
    zoomIndex > 0,
    zoomIndex < ZOOM_LEVELS.length - 1,
  ];
  const [onZoomOut, onZoomIn] = [
    canZoomOut ? () => setZoomIndex(zoomIndex - 1) : undefined,
    canZoomIn ? () => setZoomIndex(zoomIndex + 1) : undefined,
  ];

  const ZoomButton = ({
    disabled,
    onClick,
    children,
  }: {
    disabled: boolean;
    onClick?: () => void;
    children: React.ReactNode;
  }) => (
    <button
      className={classNames(
        'group flex flex-1 items-center justify-center border-t border-[#BDC9C8] p-[5px] first:border-t-0'
      )}
      disabled={disabled}
      onClick={onClick}
    >
      {children}
    </button>
  );

  return (
    <div className='absolute bottom-11 right-11 z-50 box-border flex flex-col items-stretch rounded-[5px] border border-[#6E7979] bg-white p-[2px] shadow-md'>
      <ZoomButton disabled={!canZoomIn} onClick={onZoomIn}>
        <MdAdd
          className={classNames('h-5 w-5 transition-colors', {
            'text-[#506262] group-hover:text-[#002727]': canZoomIn,
            'text-[#181C1C29]': !canZoomIn,
          })}
        />
      </ZoomButton>
      <ZoomButton disabled={!canZoomOut} onClick={onZoomOut}>
        <MdRemove
          className={classNames('h-5 w-5 transition-colors', {
            'text-[#506262] group-hover:text-[#002727]': canZoomOut,
            'text-[#181C1C29]': !canZoomOut,
          })}
        />
      </ZoomButton>
    </div>
  );
}

function DateRangeNavButton({
  direction,
  onClick,
}: {
  direction: 'next' | 'prev';
  onClick: () => void;
}) {
  return (
    <button
      className='tranition-colors flex h-7 w-7 items-center justify-center rounded-full bg-[#EBEEEE] text-tertiary hover:bg-[#DBDEDE]'
      onClick={onClick}
    >
      {direction === 'prev' ? (
        // ? Should probably be using the @fortawesome/pro-regular-svg-icons package but I couldn't get it to work
        <FontAwesomeIcon icon={faChevronLeft} fontWeight={400} />
      ) : (
        <FontAwesomeIcon icon={faChevronRight} fontWeight={400} />
      )}
    </button>
  );
}

function applyFilters(
  data: ScheduleViewData | undefined,
  filters: Record<string, string[] | undefined> | undefined
) {
  if (!(data && filters)) return data;

  const checkIn = filters.checkIn?.[0];
  const checkOut = filters.checkOut?.[0];
  const checkInRange = createFilterRange(checkIn);
  const checkOutRange = createFilterRange(checkOut);

  const filterScheduled = filters.scheduled?.[0];
  const filterScheduledRange = createFilterRange(filterScheduled);

  const attributeFilters = parseAttributeFilters(filters['attributes']);

  return (
    data
      .filter((site) => {
        return (
          (filters.locationName?.length
            ? filters.locationName.includes(site.name)
            : true) &&
          attributeFilters.every((attributeFilter) => {
            const attribute = site.attributes?.find(
              (attribute) => attribute.id === attributeFilter.id
            );
            if (!attribute) return false;
            return attributeFilter.value.every((value) =>
              attribute.value.includes(value)
            );
          })
        );
      })
      .map((site) => {
        return {
          ...site,
          top:
            /* Lengthy condition explanation:
            this site should be 'top' if:
            (
              check in and check out filters are unset
              OR
              this site has a booking which satisfies EITHER filter
            )
            AND
            (
              there are no non-booking related filters set
              OR
              this site has a non-booking event which satisfies all relevant set filters
            )
            */
            ((!checkInRange && !checkOutRange) ||
              site.calendar
                .filter((event) => event.status === 'Booking')
                .some((event) => {
                  const match = Boolean(
                    (checkInRange &&
                      isWithinInterval(new Date(event.start), checkInRange)) ||
                      (checkOutRange &&
                        isWithinInterval(
                          new Date(event.end || event.start),
                          checkOutRange
                        ))
                  );
                  return match;
                })) &&
            (!Object.keys(filters).some((filterName) =>
              ['scheduled', 'status', 'assignee', 'included', 'tags'].includes(
                filterName
              )
            ) ||
              site.calendar
                .filter((event) => event.status !== 'Booking')
                .some((event) => {
                  var valid = true;

                  const eventScheduledRange = {
                    start: event.start
                      ? new Date(event.start)
                      : new Date(event.end),
                    end: event.end
                      ? new Date(event.end)
                      : new Date(event.start),
                  } as Interval;

                  if (
                    filterScheduledRange &&
                    !areIntervalsOverlapping(
                      eventScheduledRange,
                      filterScheduledRange
                    )
                  )
                    valid = false;

                  if (
                    filters.status &&
                    !isValueAllowedByFilter(event.status, filters.status)
                  )
                    valid = false;

                  if (
                    filters.assignee &&
                    !isValueAllowedByFilter(
                      event.assignee?.id,
                      filters.assignee
                    )
                  )
                    valid = false;

                  if (
                    filters.included &&
                    !isAnyValueAllowedByFilter(
                      event.included?.map(({ id }) => id) ?? [],
                      filters.included
                    )
                  )
                    valid = false;

                  if (
                    filters.tags &&
                    !filters.tags.some((filterByTagId) =>
                      event.tags?.includes(filterByTagId)
                    )
                  ) {
                    valid = false;
                  }

                  return valid;
                })),
        };
      })
      .sort((a, b) => {
        if (a.top && !b.top) return -1;
        if (!a.top && b.top) return 1;
        return 0;
      })
      // Last remove any sites with no calendar events
      // TODO Remove this... A future version will show these at the bottom e.g. so new jobs can be added on a target date
      .filter((site) => Boolean(site.top))
  );
}

function isValueAllowedByFilter(
  fieldValue: string | null | undefined,
  filter: string[] | undefined
) {
  if (!filter) return true;
  return filter.length === 0 || filter.includes(fieldValue ?? '');
}

function isAnyValueAllowedByFilter(
  fieldValues: string[],
  filter: string[] | undefined
) {
  if (!filter) return true;
  return filter.length === 0 || fieldValues.some((val) => filter.includes(val));
}

function createFilterRange(value: string | undefined): Interval | undefined {
  switch (value) {
    case undefined:
      return;
    case 'endOfWeek':
      return { start: startOfDay(new Date()), end: endOfWeek(new Date()) };
    case 'tomorrow':
      return {
        start: startOfDay(addDays(new Date(), 1)),
        end: startOfDay(addDays(new Date(), 2)),
      };
    default:
      return {
        start: startOfDay(new Date()),
        end: startOfDay(addDays(new Date(), parseInt(value))),
      };
  }
}

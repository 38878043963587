import { cacheExchange } from '@urql/exchange-graphcache';
import { relayPagination } from '@urql/exchange-graphcache/extras';
import { differenceInCalendarDays } from 'date-fns';
import { parse } from 'graphql';
import { gql } from 'urql';
import {
  GraphCacheConfig,
  ItemsDocument,
  JobStatus,
  JobsDocument,
  QueryJobBoardArgs,
} from './generated/graphql';
import schema from './generated/schema';
import {
  ListAssetsDocument,
  ListJobsDocument,
  ScheduleDocument,
} from './gql/graphql';

export const cache = cacheExchange<GraphCacheConfig>({
  schema,
  keys: {
    Attribute: () => null,
    AttributeSingle: () => null,
    Role: (role) => role.key!,
    VariantType: () => null,
    VariantValue: () => null,
  },
  resolvers: {
    Query: {
      spacesConnection: relayPagination(),
    },
  },
  updates: {
    Mutation: {
      addItem(result, _args, cache, _info) {
        cache
          .inspectFields('Query')
          .filter((field) => field.fieldName === 'items')
          .forEach((field) => {
            cache.updateQuery(
              {
                query: ItemsDocument,
                variables: field.arguments,
              },
              (data) => {
                // @ts-ignore
                data.items.push(result.addItem);
                return data;
              }
            );
          });
      },

      createAsset(result, _args, cache, _info) {
        cache.updateQuery({ query: ListAssetsDocument }, (data) => {
          // @ts-expect-error FIXME
          data.assets.push(result.createAsset);
          return data;
        });
      },

      createContact(_, _args, cache, _info) {
        cache
          .inspectFields('Query')
          .filter((field) => field.fieldName === 'contacts')
          .forEach((field) => {
            cache.invalidate('Query', field.fieldKey);
          });
      },

      deleteJob(result, args, cache, _info) {
        cache
          .inspectFields('Query')
          .filter((field) => field.fieldName === 'jobs')
          .forEach((field) => {
            cache.updateQuery(
              {
                query: JobsDocument,
                variables: field.arguments,
              },
              (data) => {
                if (
                  data &&
                  Array.isArray(data?.jobs) &&
                  result.deleteJob.output === 1
                ) {
                  data.jobs = data.jobs.filter((job) => job.id !== args.id);
                }
                return data;
              }
            );
          });

        cache.updateQuery({ query: ScheduleDocument }, (data) => {
          // ? This can be optimized based on the assumption that event.id is globally unique for events
          for (const site of data?.sites ?? []) {
            site.calendar = site.calendar.filter(
              (event) => event.id !== args.id
            );
          }
          return data;
        });

        // cache.writeFragment(
        //   gql`
        //     fragment _ on ScheduleEvent {
        //       id
        //       status
        //     }
        //   `,
        //   {
        //     id: _args.id,
        //     status: JobStatus.Deleted,
        //   }
        // );
      },

      createTag(result, _args, cache, _info) {
        cache
          .inspectFields('Query')
          .filter((field) => field.fieldName === 'tags')
          .forEach((field) => {
            // cache.updateQuery(
            //   {
            //     query: ListTagsDocument,
            //     variables: { entityTypes: field.arguments.entityTypes },
            //   },
            //   (data) => {
            //     console.log('d', data);
            //     data?.tags.edges.push({ node: result.createTag });
            //     return data;
            //   }
            // );
            cache.invalidate('Query', field.fieldKey);
          });
      },

      rescheduleJob(result, _args, cache, _info) {
        cache.writeFragment(
          gql`
            fragment _ on ScheduleEvent {
              id
              start
              end
            }
          `,
          {
            id: result.rescheduleJob.id,
            start:
              result.rescheduleJob.scheduleStart ??
              result.rescheduleJob.scheduleEnd,
            end: result.rescheduleJob.scheduleEnd,
          }
        );
      },

      revertJobStatus(result, _args, cache, _info) {
        if (!result.revertJobStatus) return;

        cache.writeFragment(
          parse(`
            fragment _ on ScheduleEvent {
              id
              status
            }
          `),
          {
            id: result.revertJobStatus.id,
            status: result.revertJobStatus.status,
          }
        );
      },

      completeJob(result, _args, cache, _info) {
        if (!result.completeJob) return;

        cache.writeFragment(
          parse(`
            fragment _ on ScheduleEvent {
              id
              status
            }
          `),
          {
            id: result.completeJob.id,
            status: result.completeJob.status,
          }
        );
      },

      cancelJob(result, _args, cache, _info) {
        if (!result.cancelJob) return;

        cache.writeFragment(
          parse(`
            fragment _ on ScheduleEvent {
              id
              status
            }
          `),
          {
            id: result.cancelJob.id,
            status: result.cancelJob.status,
          }
        );
      },

      startJob(result, _args, cache, _info) {
        if (!result.startJob) return;

        cache.writeFragment(
          parse(`
            fragment _ on ScheduleEvent {
              id
              status
            }
          `),
          {
            id: result.startJob.id,
            status: result.startJob.status,
          }
        );
      },

      updateJob(result, args, cache, _info) {
        if (!result.updateJob) return;

        // Updates cache of jobBoard
        cache
          .inspectFields('Query')
          .filter((field) => field.fieldName === 'jobBoard')
          .forEach((field) => {
            const { scheduleFrom, scheduleTo, ...rest } =
              field.arguments as QueryJobBoardArgs;
            cache.updateQuery(
              {
                query: ListJobsDocument,
                variables: {
                  ...rest,
                  dateAfter: scheduleFrom,
                  dateBefore: scheduleTo,
                },
              },
              (data) => {
                data?.jobBoard.sort((a, b) => {
                  const dateA = new Date(
                    a.__typename === 'Job'
                      ? a.scheduleStart
                      : a.summary === 'Check-in'
                      ? a.start
                      : a.end
                  );
                  const dateB = new Date(
                    b.__typename === 'Job'
                      ? b.scheduleStart
                      : b.summary === 'Check-in'
                      ? b.start
                      : b.end
                  );

                  if (dateA > dateB) return 1;
                  if (dateA < dateB) return -1;
                  return 0;
                });

                return data;
              }
            );
          });

        cache.writeFragment(
          parse(`
            fragment _ on ScheduleEvent {
              id
              summary
              status
              start
              end
              scheduledStartTime
              scheduledEndTime
              assignee {
                id
                name
                image
              }
            }
          `),
          {
            id: result.updateJob.id,
            summary: result.updateJob.name,
            status: result.updateJob.status,
            start:
              result.updateJob.scheduleStart ?? result.updateJob.scheduleEnd,
            end: result.updateJob.scheduleEnd,
            scheduledStartTime: args.input.scheduledStartTime,
            scheduledEndTime: args.input.scheduledEndTime,
            assignee: result.updateJob.assignee
              ? {
                  __typename: result.updateJob.assignee.__typename,
                  id: result.updateJob.assignee.id,
                  name: result.updateJob.assignee.name,
                  image: result.updateJob.assignee.image ?? null,
                }
              : null,
          }
        );

        // Handle changed site
        // * updateJob mutations always provide a location, so this is always true
        // @ts-expect-error this is probably broken
        if (args.input.location) {
          // Find the old event in the cache to reinstate it later
          const oldEvent = cache
            .readQuery({
              query: ScheduleDocument,
            })
            ?.sites.flatMap((site) => site.calendar)
            .find((event) => event.id === args.input.id);

          if (!oldEvent) {
            console.error(
              `Could not find event in cache to update site. event.id: ${args.input.id} event.name: ${args.input.name}`
            );
            return;
          }

          // Remove the old event from the old site's calendar
          cache.updateQuery({ query: ScheduleDocument }, (data) => {
            // Iterate every site and remove all matching events
            // ? This can be optimized since we are already making a search for the old event earlier
            for (const site of data?.sites ?? []) {
              site.calendar = site.calendar.filter(
                (event) => event.id !== args.input.id
              );
            }
            return data;
          });

          // Add the old event to the new site's calendar
          cache.updateQuery({ query: ScheduleDocument }, (data) => {
            const site = data?.sites.find(
              // @ts-expect-error this is probably broken
              (site) => site.id === args.input.location.split(':')[1] // location is in the format "Site:{siteId}"
            );

            site?.calendar.push(oldEvent);
            return data;
          });
        }
      },

      createJobs(result, args, cache, _info) {
        if (!result.createJobs) return;

        cache.updateQuery({ query: ScheduleDocument }, (data) => {
          // Add the new events to their site's calendar
          for (const event of result.createJobs) {
            if (event.location === undefined) {
              console.error(
                `Event ${event.id} does not have a location, skipping adding to calendar`
              );
              continue;
            }

            const site = data?.sites.find(
              (site) => site.id === event.location?.id
            );

            const days =
              event.scheduledEndDate && event.scheduledStartDate
                ? differenceInCalendarDays(
                    event.scheduledEndDate,
                    event.scheduledStartDate
                  ) + 1
                : 1;

            site?.calendar.push({
              __typename: 'ScheduleEvent',
              id: event.id!,
              summary: event.name ?? '',
              start: event.scheduledStartDate ?? event.scheduledEndDate ?? null,
              scheduledStartTime: event.scheduledStartTime ?? null,
              end: event.scheduleEnd ?? null,
              scheduledEndTime: event.scheduledEndTime ?? null,
              status: event.status ?? JobStatus.Created,
              days,
              assignee: event.assignee ?? null,
              included: event.include ?? null,
              // @ts-expect-error FIXME
              attributes: event.attributes ?? null,
              tags: null,
            });
          }

          return data;
        });
      },

      completeAttributeTask(result, args, cache, _info) {
        cache
          .inspectFields('Query')
          .filter((field) => field.fieldName === 'task')
          .forEach((field) => {
            cache.invalidate('Query', field.fieldKey);
          });
      },
    },
  },
});

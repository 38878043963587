import { Transition } from '@headlessui/react';

type Props = {
  children: React.ReactNode;
};

export function MenuTransition(props: Props) {
  return (
    <Transition
      leave='transition ease-in duration-100'
      leaveFrom='opacity-100'
      leaveTo='opacity-0'
      {...props}
    />
  );
}

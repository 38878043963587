import { useQuery } from 'urql';
import { FacetedFilterOption } from '~/components/ui/FacetedFilterSelect';
import { graphql } from '~/gql';
import { SitesQuery } from '~/gql/graphql';
import { unique } from '~/helpers/array';

type Site = SitesQuery['sites'][number];

const SitesDocument = graphql(`
  query Sites {
    sites {
      id
      status
      type
      name
      address
      state
      image
      activeLocations
      attributes(filter: true) {
        id
        type
        category
        name
        value
      }
    }
  }
`);

export function useSites(): Site[] {
  const [result] = useQuery({
    query: SitesDocument,
    requestPolicy: 'cache-and-network',
  });

  return result.data?.sites ?? [];
}

export function extractAttributes(sites: Site[]) {
  return (
    sites?.reduce<FacetedFilterOption[]>((acc, site) => {
      site.attributes?.forEach((attribute) => {
        if (attribute.type === 'attachment') {
          return;
        }
        if (!acc.some((opt) => opt.key === attribute.id)) {
          const group = attribute.category;
          const label = attribute.name;
          acc.push({
            $group: group?.trimEnd(),
            key: attribute.id,
            label: label.trimStart(),
            value: attribute.value,
          });
        } else {
          const index = acc.findIndex(
            (opt: FacetedFilterOption) => opt.key === attribute.id
          );
          acc[index].value = unique([...acc[index].value, ...attribute.value]);
        }
      });
      return acc;
    }, []) ?? []
  );
}

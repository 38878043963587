import { Dialog, Transition, TransitionChild } from '@headlessui/react';
import React, { Fragment } from 'react';

type Props = {
  children: React.ReactNode;
  isOpen: boolean;
  onClose: () => void;
};

export function UserSettingsPopup({ children, isOpen, onClose }: Props) {
  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as='div' className='fixed inset-0 z-[70]' onClose={onClose}>
        <div className='flex text-center h-screen md:px-4 lg:items-center'>
          <TransitionChild
            enter='ease-out duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='ease-in duration-400'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <div
              onClick={onClose}
              className='fixed inset-0 bg-slate-800 bg-opacity-60 lg:bg-opacity-20'
            ></div>
            {/* <DialogPanel></DialogPanel> */}
          </TransitionChild>

          {/* This element is to trick the browser into centering the modal contents. */}

          <TransitionChild
            enter='ease-out duration-300 origin-bottom lg:origin-center'
            enterFrom='opacity-0 scale-y-0 lg:scale-y-100'
            enterTo='opacity-100 scale-y-100'
            leave='ease-in duration-300 origin-bottom'
            leaveFrom='opacity-100 scale-y-100'
            leaveTo='opacity-0 scale-y-0 lg:scale-y-100'
          >
            <div className='absolute bottom-0 left-0 right-0 top-0.5 my-6 mb-0 ml-auto mr-auto flex w-full items-center justify-center text-left lg:bottom-auto lg:top-auto lg:w-[800px] '>
              <div className='relative h-full w-full overflow-y-auto rounded-2xl rounded-b-none bg-white lg:max-h-[90vh] lg:rounded-b-2xl'>
                <div className='flex w-full items-center justify-start bg-white pb-0 pl-6 pt-5 lg:p-10 lg:pb-0 lg:pt-7'>
                  <button
                    type='button'
                    onClick={onClose}
                    className='x h-12 w-12 text-copy-nav hover:text-brand'
                  ></button>
                </div>
                {children}
              </div>
            </div>
          </TransitionChild>
        </div>
      </Dialog>
    </Transition>
  );
}
